import axios from "axios";
import { useState, useEffect } from "react";
import { Modal, Input, Button, Statistic } from "antd";
const { Countdown } = Statistic;

import styles from "../styles/OTPVerificationModal.module.css";

export default function OTPVerificationModal({
  isOpen,
  onClose,
  onVerify,
  resendTimeout,
  otpMethod,
  username,
  openNotification,
  confirmLoading
}) {
  const [otpValue, setOtpValue] = useState("");
  const [deadline, setDeadline] = useState(null);
  const [showResendButton, setShowResendButton] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setOtpValue("");
      setShowResendButton(false);
      setDeadline(Date.now() + resendTimeout * 1000);
    }
  }, [isOpen, resendTimeout]);

  const handleResendCodeClick = async (e) => {
    e.preventDefault();
    try {
      const response = await axios({
        url: "https://eperformax-test.callcenterstudio.com/api/",
        method: "GET",
        params: {
          email: username,
          function: "request_otp_code",
        },
        timeout: 5000,
      });

      if(response.status == 200 && response.data.success) {
        setShowResendButton(false);
        setDeadline(Date.now() + (response?.data?.remaining_time || 180) * 1000);
        if(response.data.message) {
          openNotification(response?.data?.message);    
        }
      }
    } catch (err) {
      openNotification(err.message);
    }
  };

  const otpInputDescription =
    otpMethod === "otp_app"
      ? "You may get the verification code from the extension or the mobile app."
      : "Please enter the verification code sent to your Phone or E-Mail address";

  return (
    <Modal
      title="Verification Code"
      className={styles.modalCustom}
      open={isOpen}
      maskClosable={false}
      confirmLoading={confirmLoading}
      destroyOnClose={true}
      width={650}
      onOk={() => onVerify(otpValue)}
      onCancel={onClose}
      cancelButtonProps={{
        style: {
          backgroundColor: '#f5222d',
          color: '#ffffff',
          borderColor: 'transparent',
          fontWeight: 800,
          fontSize: '1rem'
        }
      }}
      okButtonProps={{
        style: {
          backgroundColor: '#52c41a',
          color: '#ffffff',
          borderColor: 'transparent',
          fontWeight: 800,
          fontSize: '1rem'
        }
      }}
    >
      <p>{otpInputDescription}</p>
      <div className={styles.modalBody}>
        <Input
          value={otpValue}
          onChange={(e) => setOtpValue(e.target.value)}
          autoFocus
          className={styles.otpInput}
        />
        {otpMethod !== "otp_app" &&
          (showResendButton ? (
            <Button
              size="small"
              onClick={handleResendCodeClick}
              type="link"
              className={styles.resendButton}
            >
              Resend Code
            </Button>
          ) : (
            <Countdown
              format="mm:ss"
              valueStyle={{
                fontSize: "1rem",
                color: "#fd622c",
              }}
              value={deadline}
              onFinish={() => setShowResendButton(true)}
            />
          ))}
      </div>
    </Modal>
  );
}
